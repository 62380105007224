<template>
  <div style="margin: 0 auto">
    <p
      style="
        font-size: 0.55rem;
        text-align: center;
        margin-top: 0.48rem;
        margin-bottom: 0.1rem;
        color: #888888;
      "
    >
      美好生活 快乐成长
    </p>
    <p style="font-size: 0.18rem; text-align: center; color: #acacac; margin-bottom: 0.42rem ">
      HAPPY LIFE HAPPY GROWTH
    </p>
    <div
      style="
        background-color: #f2f2f2;
        padding: 0.4rem 0.5rem;
        width: 7.5rem;
        border-bottom: 1px solid #e9e4e4;">
      <p style="color: green; font-size: 0.3rem; font-weight: bold">我要合作</p>
      <div style="margin-top: 0.5rem; margin-bottom: 0.4rem">
        <p style="font-size: 0.2rem ; margin-bottom: .1rem;">联系电话:18922843324</p>
        <p style="font-size: 0.2rem">
          公司地址:深圳市宝安区石岩街道浪心社区朗日工业园
        </p>
      </div>
      <div class="official">
        <div>
          <img src="@/assets/imgs/home/z.png" alt="" />
          <p><img src="@/assets/imgs/home/jd.png" alt="" /><a href="https://mall.jd.com/index-11533218.html?from=pc" target="_blank">京东旗舰店</a></p>
        </div>
        <div>
          <img src="@/assets/imgs/home/y.png" alt="" />
          <p><img src="@/assets/imgs/home/tm.png" alt="" /><a href="https://huanhesp.tmall.com/shop/view_shop.htm?spm=a230r.1.0.0.280f77adyijx8b" target="_blank">天猫旗舰店</a></p>
        </div>
      </div>
    </div>
    <p
      style="
        text-align: center;
        font-size: 0.16rem;
        margin-top: 0.2rem;
       
        transform: scale(0.9);
      ">
      Copyright © 2019-2023 深圳市欢禾实业有限公司 huanhemi.com版权所有
    </p>
    <p style="text-align: center; font-size: 0.26rem; transform: scale(0.9)">
      <a target="_blank" href="https://beian.miit.gov.cn">粤ICP备2021097383号</a>
    </p>
    <p
      style="
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.26rem;
        transform: scale(0.9);
      "
    >
      <img src="@/assets/imgs/home/police.png" alt=" " style="width: 0.25rem" />
     <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007765"> 粤公网安备 44030602007765号</a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.official {
  display: flex;
  width: 70%;
  margin-top: 3%;
  justify-content: space-between;
  div {
    width: 43%;
  }
  img {
    object-fit: contain;
    width: 100%;
  }
  p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.2rem;
    margin-top: 0.2rem;
    img {
      width: 20%;
      margin-right: 8%;
      object-fit: contain;
    }
  }
}
</style>