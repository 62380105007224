<template>
  <div ref="box">
    <div class="headbox" :class="{'ancestor': type }">
      <p>
        <span
          class="iconfont icon-caidan"
          @click="drawer = true"
          style="margin-left: 0.3rem; margin-right: 0"
        ></span>
        <span
          class="iconfont icon-sousuo"
          style="margin-left: 0.3rem; margin-right: 0"
        ></span>
      </p>
      <img src="../assets/imgs/home/logo.png" alt="" />
      <p>
        <span class="iconfont icon-lianxiren"></span>
        <span class="iconfont icon-gouwuche" @click="gocart()"></span>
      </p>
      <el-drawer
        direction="ltr"
        :append-to-body="true"
        :visible.sync="drawer"
        :with-header="false"
        :before-close="handleClose"
      >
        <ul>
          <li @click="skip()">
            <RouterLink :to="{ name: 'Home' }">首页</RouterLink>
          </li>
          <li @click="skip(true)">商店</li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'HappyRice' }">欢禾</RouterLink>
          </li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'WinnerRice' }">佼稻香</RouterLink>
          </li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'Excellent' }">鲜稻佳</RouterLink>
          </li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'About' }">关于我们</RouterLink>
          </li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'Contact' }">联系我们</RouterLink>
          </li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'recruit' }">加入我们</RouterLink>
          </li>
          <li @click="skip()">
            <RouterLink :to="{ name: 'News' }">新闻中心</RouterLink>
          </li>
        </ul>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      type: "",
      navlist: [
        "首页",
        "商店",
        "欢禾",
        "佼稻香",
        "鲜稻佳",
        "关于我们",
        "新闻中心",
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleClose(done) {
      done();
    },
    gocart() {
      window.open("https://shop.huanhemi.com");
    },
    skip(type) {
      if (type) {
        window.open("https://shop.huanhemi.com");
      }
      this.drawer = false;
    },
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop;
      this.$nextTick(() => {
        if (scrollTop > this.$refs.box.offsetHeight) {
          this.type = true;
        } else {
          this.type = false;
        }
      });
    },
  },
};
</script>

<style lang='scss'>
.ancestor {
  width: 100%;
  position: fixed;
  //  background-color: transparent;
  background: white;
  opacity: 0.8;
  top: 0;
  z-index: 99;
  transition: 0.3s linear;
}
.headbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 5%;
  img {
    object-fit: contain;
    width: 2.5rem;
    height: 0.6rem;
  }
  p {
    span {
      font-size: 0.45rem;
      margin-right: 0.3rem;
    }
  }
}
.el-drawer__body {
  ul {
    margin-top: 0.4rem;
    li {
      font-size: 0.3rem;

      margin-bottom: 0.42rem;
      padding-left: 15%;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

.iconfont {
  color: rgb(12, 238, 12);
}
</style>