//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
  mode: 'history',
  //4.配置路由的path和组件
  routes: [
    {
      path: "/",
      name: 'Home',
      component: () => import("../pages/Home/MyHome.vue"),
    },
    {
      path: "/HappyRice",
      name: 'HappyRice',
      component: () => import("../pages/HappyRice/HappyRice.vue"),
    },
    {
      path: "/About",
      name: 'About',
      component: () => import("@/pages/About/About.vue"),
    },
    {
      path: "/News",
      name: 'News',
      component: () => import("@/pages/News/News.vue"),
    },
    {
      path: '/Contact',
      name: 'Contact',
      component: () => import("@/pages/Contact/Contact.vue")
    },
    {
      path: '/WinnerRice',
      name: 'WinnerRice',
      component: () => import('@/pages/WinnerRice/WinnerRice.vue')
    },
    {
      path: '/Excellent',
      name: 'Excellent',
      component: () => import('@/pages/Excellent/Excellent.vue')
    },
    {
      path:'/NewsDetails',
      name:'NewsDetails',
      component:()=> import('@/pages/NewsDetails/NewsDetails.vue')
    },
    {
      path:'/recruit',
      name:'recruit',
      component:()=> import('@/pages/recruit/recruit.vue')
    },
    {
     path:'/PressCenter',
     name:'PressCenter',
     component:()=>import('@/pages/PressCenter/PressCenter.vue')
    },
    {
      path: '/Product',
      name: 'Product',
      component: () => import('@/pages/Product/Product.vue'),
      children:[
        {
          path:'TimeRice',
          name:'TimeRice',
          component: ()=> import('@/pages/Product/huanhe/TimeRice.vue')
        },
        {
          path:'MollyRice',
          name:'MollyRice',
          component: ()=> import('@/pages/Product/huanhe/MollyRIce.vue')
        },
        {
          path:'PureRice',
          name:'PureRice',
          component: ()=> import('@/pages/Product/huanhe/PureRice.vue')
        },
        {
          path:'AceblueRice',
          name:'AceblueRice',
          component: ()=>import('@/pages/Product/jdx/AceblueRice.vue')
        },
        {
          path:'AceredRice',
          name:'AceredRice',
          component: ()=>import('@/pages/Product/jdx/AceredRice.vue')
        },
        {
          path:'HeathRice',
          name:'HeathRice',
          component:()=>import('@/pages/Product/jdx/Heath.vue')
        },
        {
          path:'TomRice',
          name:'TomRice',
          component:()=>import('@/pages/Product/jdx/TomRice.vue')
        },
        {
          path:'AlpRice',
          name:'AlpRice',
          component:()=>import('@/pages/Product/xdj/AlpRice.vue')
        },
        {
          path:'SwordRice',
          name:'SwordRice',
          component:()=>import('@/pages/Product/xdj/SwordRice.vue')
        },
        {
          path:'NredRice',
          name:'NredRice',
          component:()=>import('@/pages/Product/xdj/NredRice.vue')
        },
        {
          path:'NgreenRice',
          name:'NgreenRice',
          component:()=>import('@/pages/Product/xdj/NgreenRice.vue')
        }
      ]
    },
  ],
})
//5.导出路由实例
export default router
