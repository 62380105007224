import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import { DropdownMenu, DropdownItem,Search,Popup,TreeSelect,Toast,Empty,Grid, GridItem } from 'vant';
import BaiduMap from 'vue-baidu-map'
import 'vant/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css';
import './style/font/iconfont'
import './style/font/iconfont.css'
import "swiper/css/swiper.css";
import router from './router/index';



Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(DropdownMenu);
Vue.use(DropdownItem); 
Vue.use(Search);
Vue.use(Popup)
Vue.use(TreeSelect) 
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(BaiduMap, { ak: 'G0m2G2c21fwhKzD1GBlUtHENFQh0PCsX' })


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
router.afterEach((to,from,next) => { //设置路由后置守卫  路由跳转之后 页面回到顶部
  window.scrollTo(0,0);
})

