<template>
  <div id="app">
    <Head ></Head>
    <router-view></router-view>
   <MyFooter v-show="show"></MyFooter>
  </div>
</template>
<script setup>
import Head from "./components/MyHead.vue";
import MyFooter from "./components/MyFooter.vue";
</script>
<script>
export default {
  components: { MyFooter },
  data(){
    return{
      show:true
    }
  },
  // mounted(){
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  methods:{
    handleScroll() {
      // const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      // console.log(this.$refs.box.$refs.box);
      // this.$refs.box.$refs.box. classList.add('')
      // console.log(this.$refs.box.$refs.box.offsetHeight );
      // this.isSticky = scrollTop > 80 // 当页面滚动超过100px时启用固定
      // this.isSire = this.$refs.left.offsetHeight - scrollTop < 510
      // if (this.isSire) {
      //   this.isSticky = false
      // }
    },
   },
   watch: {
    $route(to, from) {
      if(to.path === "/recruit"){
        this.show = false;
      }else{
        this.show = true;
      }
    }

  
}
};
</script>

<style src="./style/app.scss" lang="scss">
</style>